import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload, Modal, DatePicker, Row, Col, message, Button, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './datepicker.css'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const beforeUploadImage = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('圖片大小不可超過 2MB!');
        return Upload.LIST_IGNORE
    }
    return isLt2M;
};


const beforeUploadVideo = (file) => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
        message.error('影片大小不可超過 20MB!');
        return Upload.LIST_IGNORE
    }
    return isLt20M;
};

const UploadModal = ({ openModal, handleClose, productRecordId, orderItem }) => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([])
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState()
    const [finishTime, setFinishTime] = useState(null)

    const [selectedType, setSelectedType] = useState('image')

    const [isUpload, setIsUpload] = useState(false)

    useEffect(() => {
        if (openModal) {
            setFinishTime(null);
            setFileList([]);
            setImages([]);
            setVideo(null);
        }
    }, [openModal])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (finishTime && (video || images.length > 0)) {
            setIsUpload(true)
            const formData = new FormData();
            formData.append('productRecordId', productRecordId);
            formData.append('finishTime', finishTime);

            // 透過形態來判斷要上傳哪一個
            if (selectedType === 'video') {
                console.log(video)
                formData.append('video', video.originFileObj);
                axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/process/manage/video/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token')
                    }
                }).then(data => {
                    alert('影片上傳成功')
                    handleClose()
                }).catch(err => {
                    alert('影片上傳失敗')
                    console.log(err)
                }).finally(() => {
                    setIsUpload(false)
                })
            } else {
                images.forEach((image, index) => {
                    console.log(image)
                    formData.append('image', image);
                });

                axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/process/manage/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token')
                    }
                }).then(data => {
                    alert('圖片上傳成功')
                    handleClose()
                }).catch(err => {
                    alert('圖片上傳失敗')
                    console.log(err)
                }).finally(() => {
                    setIsUpload(false)
                })
            }
        } else {
            alert('請選擇完成日期或上傳至少一張圖片')
        }
    };

    const handlePreview = async (file) => {
        console.log(file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => {
        console.log(newFileList)
        if (newFileList.length > 5) {
            alert('最多只能上傳五張圖片，請重新選擇！')
            return
        } else {
            const fileList = newFileList.map(each => { return each.originFileObj })
            console.log(fileList)
            //後端儲存
            setImages(fileList)
            // 前端顯示
            setFileList(newFileList)
        }

    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                <p style={{ margin: '5px' }}>Upload</p>（上限2MB）
            </div>
        </button>
    );

    const handleVideoChange = ({ fileList: newFileList }) => {
        const file = newFileList[0];
        setVideo(file);
    };

    // 強制status變成done，讓圖片不會有紅匡
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Modal
            title={`上傳製程流程圖片 - ${orderItem?.method}`}
            centered
            open={openModal}
            onOk={handleSubmit}
            okButtonProps={{ disabled: isUpload ? true : false, loading: isUpload ? true : false }}
            okText='存檔'
            cancelText='取消'
            onCancel={handleClose}
        >
            <div style={{ padding: 20 }}>
                <Row gutter={24} >
                    <Col span={8}>完成日期</Col>
                    <Col span={16}>
                        <DatePicker placeholder="請選擇製程完成時間"
                            key={finishTime ? finishTime : 'empty'}
                            format={'YYYY-MM-DD'}
                            defaultValue={finishTime ? dayjs(finishTime, 'YYYY-MM-DD') : null}
                            onChange={(e) => setFinishTime(e ? e.format('YYYY-MM-DD') : null)}
                            popupClassName="process-datepicker-dropdown" />
                    </Col>
                </Row>
                <br />
                {orderItem && orderItem.id === 1 ?
                    <div>
                        <Row gutter={24} >
                            <Col>檔案類型</Col>
                            <Col >
                                <Radio.Group name="radiogroup" defaultValue={selectedType} onChange={e => setSelectedType(e.target.value)}>
                                    <Radio value='video'>影片</Radio>
                                    <Radio value='image'>圖片</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <div style={{ paddingTop: '20px' }}>
                            {selectedType === 'video' ?
                                <Upload
                                    listType="picture"
                                    accept='video/*'
                                    beforeUpload={beforeUploadVideo}
                                    onChange={handleVideoChange}
                                    maxCount={1}
                                    customRequest={dummyRequest}
                                >
                                    {video ? null : <Button icon={<UploadOutlined />}>上傳影片（上限20MB）</Button>}
                                </Upload>
                                :
                                <>
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        multiple={true}
                                        accept='image/*'
                                        beforeUpload={beforeUploadImage}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        customRequest={dummyRequest}
                                    >
                                        {fileList.length >= 5 ? null : uploadButton}
                                    </Upload>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{
                                                display: 'none',
                                            }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </>
                            }
                        </div>

                    </div>
                    :
                    <>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            multiple={true}
                            accept='image/*'
                            beforeUpload={beforeUploadImage}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            customRequest={dummyRequest}
                        >
                            {fileList.length >= 5 ? null : uploadButton}
                        </Upload>
                        {previewImage && (
                            <Image
                                wrapperStyle={{
                                    display: 'none',
                                }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </>
                }

            </div>

        </Modal>

    );
};

export default UploadModal;
