import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter , Route, Routes,Navigate } from 'react-router-dom';
import ClientApp from './client/App';
import AdminApp from './admin/App'
import './index.css'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/admin/*" element={<AdminApp/>} />
          <Route path="/client/*" element={<ClientApp/>} />
          <Route key="notFound" path="*" element={<Navigate replace to="/client/eTracking" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)