import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Modal, Form, Input } from 'antd';

function AccountResetPw({ openModal, handleClose, data }) {
    const token = localStorage.getItem('token')

    const [password, setPassword] = useState(null)
    const [checkPw, setCheckPw] = useState(null)


    const updatePw = async () => {
        try {
            // 檢查是否有變更
            if (password != checkPw) {
                alert('密碼不一致，請再確認一次！');
                return;
            } else {
                axios.put(`${process.env.REACT_APP_API_PATH}/api/admin/user/reset`, { password, id: data.id }, {
                    headers: {
                        Authorization: token
                    },
                }).then(data => {
                    console.log(data)
                    alert('密碼更新成功！')
                    handleClose();
                }).catch(err => {
                    console.log(err)
                    alert('密碼更新失敗')
                })
            }
        } catch (err) {
            if (err.errorFields) {
                // 這裡處理表單的錯誤字段
                console.log('Validation Errors:', err.errorFields);
                err.errorFields.forEach(field => {
                    alert(`更新失敗: ${field.errors}`)
                });
            }

        }
    }

    return (
        <Modal
            title="重設使用者密碼"
            centered
            open={openModal}
            onOk={updatePw}
            okText='更新密碼'
            cancelText='取消'
            onCancel={handleClose}
        >
            <Form layout="horizontal" style={{ padding: '20px' }}>
                <Form.Item
                    name="password"
                    label="新密碼"
                    rules={[{ required: true, message: '請輸入密碼' }]}
                >
                    <Input.Password placeholder="輸入密碼" value={password}
                        onChange={e => setPassword(e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="checkPw"
                    label="確認密碼"
                    rules={[{ required: true, message: '請再次輸入密碼' }]}
                >
                    <Input.Password placeholder="請再次輸入密碼" value={checkPw}
                        onChange={e => setCheckPw(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AccountResetPw